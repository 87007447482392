<template>
    <IsLoadingIcon class="is-primary-font" v-if="isProfileLoading" />
    <template v-else>
        <h1 class="title">{{ profile?.firstName }} {{ profile?.lastName }}</h1>
        <Tabs :tabs="tabs" v-model:selected-tab-id="activeTab" class="is-small"></Tabs>
        <template v-if="profile != null">
            <div style="width: 100%; max-width: 500px;">
                <template v-if="activeTab == 'account'">
                    <InputValidate label="First Name" icon="fas fa-file-signature" type="text"
                        v-model="profile.firstName" pattern=".+" />
                    <InputValidate label="Last Name" icon="fas fa-file-signature" type="text" v-model="profile.lastName"
                        pattern=".+" />                    
                    <!-- only admin can change email and abn after registration -->
                    <InputABN v-model="profile.abn" :disabled="!isAdmin" />
                    <InputValidate :disabled="!isAdmin" label="Email" icon="fas fa-at" type="email"
                        v-model="profile.email" pattern="[a-zA-Z0-9._%+\-]+@[a-zA-Z0-9.\-]+\.[a-zA-Z]{2,}$" />
                    <InputValidate label="Password" icon="fas fa-lock" type="password" v-model="profile.password" />
                    <div class="field">
                        <label class="label">Profile Picture</label>
                        <div class="control">
                            <div class="file is-small">
                                <label class="file-label">
                                    <input class="file-input input" type="file" name="resume"
                                        @change="handleFileUpload">
                                    <span class="file-cta">
                                        <span class="file-icon">
                                            <i class="fas fa-upload"></i>
                                        </span>
                                        <span class="file-label">
                                            Choose a file…
                                        </span>
                                    </span>
                                </label>
                            </div>
                        </div>
                        <img class="profile-image" :src="picture" />
                        <!-- <figure class="image is-128x128" v-if="profile && profile.picture">
                    </figure> -->
                    </div>
                </template>
                <template v-else-if="(isAdmin) && (activeTab == 'rates')">
                    <div class="notification is-primary">
                        The rate is defined in cents per hour, in other words, if the value is set as 3500, it means
                        $35.00
                        per hour.
                    </div>
                    <div class="field" v-for="groupRate in (profile as IUserAdmin).groups" :key="groupRate.id">
                        <label class="label">{{ groupRate.groupName }}</label>
                        <div class="control">
                            <input class="input" type="number" placeholder="$" v-model="groupRate.rate" />
                        </div>
                    </div>
                </template>
                <template v-else-if="(isAdmin) && (activeTab == 'filters')">
                    <!-- Advanced Filters Panel -->
                    <div v-if="crewFilter && crewFilter.length > 0">
                        <div class="columns is-multiline">
                            <!-- Dynamic Filters -->
                            <div v-for="filter in crewFilter" :key="filter.id" class="column is-half">
                                <div class="field">
                                    <label class="label">{{ filter.description }}</label>
                                    
                                    <!-- Checkbox Filter -->
                                    <div v-if="filter.type === EFilterType.checkbox" class="control">
                                        <label class="checkbox">
                                            <input type="checkbox" v-model="dynamicFilterValues[filter.id]">
                                            {{ filter.description }}
                                        </label>
                                    </div>

                                    <!-- Text Filter -->
                                    <div v-else-if="filter.type === EFilterType.text" class="control">
                                        <input class="input" type="text" v-model="dynamicFilterValues[filter.id]">
                                    </div>

                                    <!-- Multiselect Dropdown Filter -->
                                    <div v-else-if="filter.type === EFilterType.combobox" class="control">
                                        <div class="custom-multiselect">
                                            <div class="multiselect-header" @click="toggleDynamicDropdown(filter.id)">
                                                <span class="selected-text">
                                                    {{ getDynamicSelectedText(filter) }}
                                                </span>
                                                <span class="icon">
                                                    <i class="fas" :class="[openDynamicDropdowns[filter.id] ? 'fa-chevron-up' : 'fa-chevron-down']"></i>
                                                </span>
                                            </div>
                                            <div v-show="openDynamicDropdowns[filter.id]" class="multiselect-dropdown">
                                                <div class="dropdown-item" v-for="option in filter.valuecomboparsed" :key="option">
                                                    <label class="checkbox">
                                                        <input type="checkbox" 
                                                            :value="option"
                                                            v-model="dynamicFilterValues[filter.id]">
                                                        {{ option }}
                                                    </label>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </template>
                <template v-else-if="activeTab == 'personal'">
                    <InputValidate label="Birthday" icon="fas fa-birthday-cake" type="date"
                        pattern="(?:19|20)(?:[0-9]{2}-(?:(?:0[1-9]|1[0-2])-(?:0[1-9]|1[0-9]|2[0-8])|(?:(?!02)(?:0[1-9]|1[0-2])-(?:29|30))|(?:(?:0[13578]|1[02])-31))|(?:[13579][26]|[02468][048])-02-29)"
                        v-model="profileBirthday" />
                    <InputValidate label="Phone" icon="fas fa-phone" type="tel" v-model="profile.phone" />
                    <InputValidate label="Emergency Contact" icon="fas fa-first-aid" type="text"
                        v-model="profile.emergencyName" pattern=".+" />
                    <InputValidate label="Emergency Phone" icon="fas fa-phone-rotary" type="tel"
                        v-model="profile.emergencyNumber" />
                        <InputValidate label="Address" icon="fas fa-map-marker-alt" type="text" v-model="profile.address"
                        pattern=".+" />
                    <InputValidate label="Suburb" icon="fas fa-map-marker-alt" type="text" v-model="profile.suburb"
                        pattern=".+" />
                    <InputValidate label="City" icon="fas fa-map-marker-alt" type="text" v-model="profile.city"
                        pattern=".+" />
                    <div class="field">
                        <label class="label">State</label>
                        <div class="control has-icons-left ">
                            <div class="select is-fullwidth">
                                <select v-model="profile.state">
                                    <option value="NSW">NSW</option>
                                    <option value="VIC">VIC</option>
                                    <option value="QLD">QLD</option>
                                    <option value="SA">SA</option>
                                    <option value="WA">WA</option>
                                    <option value="TAS">TAS</option>
                                    <option value="NT">NT</option>
                                    <option value="ACT">ACT</option>
                                </select>
                            </div>
                            <Icon icon="fas fa-globe" class="is-left" />
                        </div>
                    </div>
                </template>
                <template v-else-if="activeTab == 'banking'">
                    <InputValidate label="Bank Name" icon="fas fa-university" type="text" v-model="profile.bankName"
                        pattern=".+" />
                    <InputValidate label="Bank Account Name" icon="fas fa-university" type="text"
                        v-model="profile.bankAccountName" pattern=".+" />
                    <InputValidate label="Bank Account Number" icon="fas fa-university" type="text"
                        v-model="profile.bankAccountNumber" pattern=".+" />
                    <InputValidate label="Bank BSB" icon="fas fa-university" type="text"
                        v-model="profile.bankAccountBSB" pattern=".+" />
                    <button @click="addcontact">add contact</button>    
                </template>
            </div>
            <p class="buttons" style="margin-top: auto; width: 100%;">
                <button v-if="isPublic && activeTab !== 'banking'" class="button is-fullwidth is-primary mt-2"
                    :class="{ 'is-loading': isLoading }" @click="nextTab">Next</button>
                <button v-else class="button is-fullwidth is-primary mt-2" :class="{ 'is-loading': isLoading }"
                    @click="saveUser">{{
        isPublic ? 'Register' : 'Save' }}</button>

                <button v-if="!isPublic" class="button is-fullwidth">Cancel</button>
            </p>
        </template>
        <Modal v-if="snackbar !== null" @onclose="snackbar = null" title="Error">
            <h1 class="title is-3">Error</h1>
            <p>
                {{ snackbar?.text }}
            </p>
        </Modal>

    </template>
</template>
<script lang="ts" setup>
import Icon from "@/components/elements/Icon.vue";
import IsLoadingIcon from "@/components/elements/IsLoadingIcon.vue";
import Tabs, { ITabObject } from "@/components/elements/Tabs.vue";
import InputABN from "@/components/forms/InputABN.vue";
import InputValidate from "@/components/forms/InputValidate.vue";
import { showModal, urlPicture } from "@/generalFunctions";
import { setSnackbar } from "@/types/global";
import { EFilterType, IAvailableCrewFilter, IUser, IUserAdmin } from "@/types/user";
import { computed, onMounted, ref, Ref, watch } from "vue";
import { useRoute } from "vue-router";
import Modal from "../components/elements/Modal.vue";
import { getUserDetails } from "../data/crews";
import { adminSaveProfile, createProfile, profile as currentProfile, doUnauthenticatedRequest, getCrewFilter, updateUserProfileAsync } from "../data/state";
import routerAdmin from './_indexAdmin';
import routerPublic from './_indexPublic';

type activeTab = "personal" | "rates" | "address" | "banking" | "account" | "filters";
function nextTab() {
    const index = tabs.findIndex((tab) => tab.id === activeTab.value);
    if (index < tabs.length - 1) {
        activeTab.value = tabs[index + 1].id as activeTab;
    }
}
const props = defineProps<{ tab?: activeTab, hash?: string }>();
const userId = defineModel('userId', { type: Number, required: true })

watch(userId, () => {
    loadProfile();
})

const profile = ref<IUser | IUserAdmin | null>(null);

const isProfileLoading = ref(false);

async function loadProfile() {
    if (!userId.value) { return setTimeout(loadProfile, 100) }// try to re-load user
    let strUserId = localStorage.getItem("userId");

    let myUserId = parseInt(strUserId!);


    isProfileLoading.value = true;
    if (isAdmin.value) {
        picture.value = urlPicture(userId.value)
        let data = await getUserDetails(userId.value);
        if (data != null) {
            profile.value = data.user;
            crewFilter.value.forEach(filter => {
                // if (filter.type === EFilterType.combobox) {
                //     // Parse the valuecombo string into array
                //     filter.valuecomboparsed = filter.valuecombo.split(';');
                // }
                
                // Find user's filter value if it exists
                const userFilter = (profile.value as IUser)?.filter?.find(f => f.idFilter === filter.id);
                // console.log("(profile.value as IUser)?.fitler",(profile.value as IUser)?.filter);
                // console.log(userFilter?.value)
                if (userFilter) {
                    switch (filter.type) {
                        case EFilterType.checkbox:
                            dynamicFilterValues.value[filter.id] = userFilter.value === 'true';
                            break;
                        case EFilterType.text:
                            dynamicFilterValues.value[filter.id] = userFilter.value;
                            break;
                        case EFilterType.combobox:
                            console.log("userFilter.value",userFilter.value)
                            if (Array.isArray(userFilter.value)){
                                dynamicFilterValues.value[filter.id] = userFilter.value as string[];
                            } else {
                                dynamicFilterValues.value[filter.id] = userFilter.value.split(";");
                            }
                            
                            break;
                    }
                } else {
                    // Initialize empty values
                    switch (filter.type) {
                        case EFilterType.checkbox:
                            dynamicFilterValues.value[filter.id] = false;
                            break;
                        case EFilterType.text:
                            dynamicFilterValues.value[filter.id] = '';
                            break;
                        case EFilterType.combobox:
                            dynamicFilterValues.value[filter.id] = [];
                            break;
                    }
                }
            });
        } else {
            profile.value = null;
        }
        isProfileLoading.value = false;
    } else if (isPublic.value) {

        const data = await doUnauthenticatedRequest<IUser | null>('/api/public/getUserPreregisterData', 'POST', { hash: props.hash! });
        console.log(data)
        data!.birthday = new Date().setHours(0, 0, 0, 0).valueOf();
        picture.value = '/img/emptyProfile.png'
        if (data != null) {
            profile.value = data;
        } else {
            profile.value = null;
        }
        isProfileLoading.value = false;
    } else {
        if (currentProfile.value === null) { return setTimeout(loadProfile, 100) }// try to re-load user
        profile.value = currentProfile.value;
        picture.value = urlPicture(myUserId)
        isProfileLoading.value = false;
    }
}

const profileBirthday = computed({
    get() {
        if (!(profile.value as IUser).birthday)
            (profile.value as IUser).birthday = new Date().setHours(0, 0, 0, 0).valueOf();
        return new Date((profile.value as IUser).birthday).toISOString().split('T')[0];
    },
    set(value) {
        (profile.value as IUser).birthday = Date.parse(value).valueOf();
    }
});
const picture = ref('');
function convertToBase64(file: File) {
    const reader = new FileReader();

    reader.onload = (e) => {
        const base64String = e.target!.result;
        picture.value = String(base64String); // Here is your base64 string
        // You can also handle the base64 string in your component, like setting it to a data property
    };

    reader.onerror = (error) => {
        console.error('Error reading file:', error);
    };

    reader.readAsDataURL(file);
}

function handleFileUpload(event: Event) {
    const input = event.target as HTMLInputElement;
    if (!input.files?.length) return;

    const file = input.files[0];
    convertToBase64(file);
}



const tabs: ITabObject[] = [];


const snackbar = ref<setSnackbar | null>(null);
const isLoading = ref(false);
const isAdmin = ref(false)
const isPublic = ref(false);

const dynamicFilterValues = ref<Record<number, string | boolean | string[]>>({});
const openDynamicDropdowns = ref<Record<number, boolean>>({});
const crewFilter = ref<IAvailableCrewFilter[]>([]);

function toggleDynamicDropdown(filterId: number) {
    openDynamicDropdowns.value[filterId] = !openDynamicDropdowns.value[filterId];
    Object.keys(openDynamicDropdowns.value).forEach(key => {
        if (Number(key) !== filterId) {
            openDynamicDropdowns.value[Number(key)] = false;
        }
    });
}

function getDynamicSelectedText(filter: IAvailableCrewFilter) {
    const values = dynamicFilterValues.value[filter.id] as string[];
    if (!values || !Array.isArray(values)) return 'Select options';
    return values.length ? `${values.length} selected` : 'Select options';
}

async function loadFilters() {
    crewFilter.value = await getCrewFilter();
    crewFilter.value.forEach(el => {
        if (el.type === EFilterType.combobox) {
            dynamicFilterValues.value[el.id] = [];
        }
    });
}

async function saveUser() {
    try {
        if (profile.value === null) {
            return;
        }
        isLoading.value = true;
        profile.value.abn = profile.value.abn.trim();
        profile.value.address = profile.value.address.trim();
        profile.value.bankAccountBSB = profile.value.bankAccountBSB.trim();
        profile.value.bankAccountName = profile.value.bankAccountName.trim();
        profile.value.bankAccountNumber = profile.value.bankAccountNumber.trim();
        profile.value.bankName = profile.value.bankName.trim();
        profile.value.city = profile.value.city.trim();
        profile.value.email = profile.value.email.trim();
        profile.value.emergencyName = profile.value.emergencyName.trim();
        profile.value.emergencyNumber = profile.value.emergencyNumber.trim();
        profile.value.firstName = profile.value.firstName.trim();
        profile.value.lastName = profile.value.lastName.trim();
        profile.value.phone = profile.value.phone.trim();
        profile.value.suburb = profile.value.suburb.trim();
        
        if (isAdmin.value) {
            //saveUserDetails
            if (isAdmin.value && crewFilter.value) {
                (profile.value as IUser).filter = crewFilter.value.map(filter => ({
                    idFilter: filter.id,
                    value: (() => {
                        const value = dynamicFilterValues.value[filter.id];
                        switch (filter.type) {
                            case EFilterType.checkbox:
                                return value ? 'true' : 'false';
                            case EFilterType.combobox:
                                return (value as string[]).join(';'); // Return array directly for combobox
                            case EFilterType.text:
                            default:
                                return String(value || '');
                        }
                    })()
                }));
            }
            // console.log("profile.value",profile.value);
            const response = await adminSaveProfile(profile.value as IUserAdmin);
            if (response.error) {
                snackbar.value = response.snackbar;
            }
        } else if (isPublic.value) {
            const response = await createProfile({ ...profile.value, picture: picture.value.replace(/^data:image\/\w+;base64,/, '') } as IUser, props.hash!);
            if (response.error) {
                showModal(response)
            } else {
                alert('Profile has been created, you will be redirected to the login page')
                window.location.href = '/';
            }
        } else {
            const response = await updateUserProfileAsync(profile.value as IUser);
            if (response.error) {
                snackbar.value = response.snackbar;
            }
        }
    } finally {
        isLoading.value = false;
    }
}

let activeTab: Ref<activeTab> = ref(props.tab || "account");
const route = useRoute();

watch(() => props.tab, (value) => {
    if (typeof value !== 'undefined') {
        activeTab.value = value as activeTab;
    }
});



onMounted(async () => {
    const admRouter = routerAdmin.currentRoute.value.path.split("/");
    const publicRouter = routerPublic.currentRoute.value.path.split("/");
    if (publicRouter.length >= 2 && publicRouter[1] == 'public') {
        isPublic.value = true;
    }
    if (admRouter.length >= 2 && admRouter[1] == 'admin') {
        isAdmin.value = true;
        tabs.push(
            { id: "account", text: "Account" },
            { id: "rates", text: "Rates" },
            { id: "personal", text: "Personal" },
            { id: "filters", text: "Filters" },
            { id: "banking", text: "Banking" },
        );
    } else {
        tabs.push(
            { id: "account", text: "Account" },
            { id: "personal", text: "Personal" },
            // { id: "address", text: "Address" },
            { id: "banking", text: "Banking" }
        );
    }
    if (typeof route.query.tab !== 'undefined') {
        activeTab.value = route.query.tab as activeTab;
    }
    if (isAdmin.value) {
        await loadFilters();
    }
    loadProfile();
});

async function addcontact(){
        const contact = {
            name: [`${profile.value?.firstName} ${profile.value?.lastName} ${profile.value?.state}`],
            tel: [profile.value?.phone],
            email: [profile.value?.email],
            address: []
        };

        try {
            if ('contacts' in navigator && 'ContactsManager' in window) {
                const props = ['name', 'email', 'tel', 'address'];
                const supported = await (navigator.contacts as any).getProperties();
                
                if (supported.length) {
                    // Create contact data
                    const newContact = await new Promise((resolve) => {
                        resolve([contact]);
                    });

                    // Try to save contact
                    await (navigator.contacts as any).select(props, { multiple: false });
                } else {
                    throw new Error('Contact properties not supported');
                }
            } else {
                // Fallback for browsers that support legacy API
                const file = new Blob([
                    `BEGIN:VCARD
VERSION:3.0
FN:${profile.value?.firstName} ${profile.value?.lastName} ${profile.value?.state}
TEL:${profile.value?.phone}
EMAIL:${profile.value?.email}
ADR:
END:VCARD`
                ], { type: 'text/vcard' });
                
                const url = URL.createObjectURL(file);
                const link = document.createElement('a');
                link.href = url;
                link.download = 'contact.vcf';
                document.body.appendChild(link);
                link.click();
                document.body.removeChild(link);
                URL.revokeObjectURL(url);
                
                
            }
        } finally {
            //
        }
}

</script>

<style lang="scss" scoped>
@import "../styles/theme.scss";

.profile-image {
    margin: 1em;
    margin-left: auto;
    margin-right: auto;
    object-fit: cover !important;
    height: 256px !important;
    width: 256px !important;
}

figure.image {
    align-self: center;
    margin-left: auto;
    margin-right: auto;
}

.icon {
    color: $grey;
}

.is-active .icon {
    color: $primary;
}

.buttons {
    align-self: flex-end;
}

:deep(.label) {
    font-weight: normal;
}

:invalid {
    border-color: $primary-color;
}

fieldset :valid+.icon {
    display: none;
}

:invalid+.icon {
    display: inline-flex;

    i {
        color: $primary-color;
    }
}

div.section {
    display: flex;
    flex-direction: column;
    flex-grow: 1;
}

.custom-multiselect {
    position: relative;
    width: 100%;
}

.multiselect-header {
    border: 1px solid #dbdbdb;
    padding: 0.5em;
    border-radius: 4px;
    cursor: pointer;
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.multiselect-dropdown {
    position: absolute;
    top: 100%;
    left: 0;
    right: 0;
    background: white;
    border: 1px solid #dbdbdb;
    border-top: none;
    border-radius: 0 0 4px 4px;
    z-index: 20;
    max-height: 200px;
    overflow-y: auto;
}

.dropdown-item {
    padding: 0.5em;
    &:hover {
        background-color: #f5f5f5;
    }
}
</style>